@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .card {
        @apply bg-white rounded-lg shadow-lg p-4;
        
    }

    .title {
        @apply text-xl font-bold;
    }
}

body {
    min-height: 100%;
    font-family: Arial, serif;
    font-weight: normal;
    background-color: #f1f1f1;
    color: #1d3d6d;
}
